import { Button, DatePicker, Select, Space, Upload } from "antd";
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import Search from "antd/es/input/Search";
import NewAFRModal from "../newAFRmodal/newAFRmodal";
import { useEffect, useState } from "react";
import { bulkCreateAFRApi } from "../../apis/afrAPIs";
import { showToastError, showToastSuccess } from "../../../../common/toasters/toasterMessages";
import dayjs from "dayjs";
import SearchSelect from "../../../../templates/IQCActivity/components/inspectionTable/components/searchSelect";
import { getIncomingPartsAPI } from "../../../../common/apis/commonApis";

export default function RMAHeader(props) {
    const SEARCH_MORE_TYPE = "searchMore";

    const { customerData, productData, supplierData, selectedAFR, setSelectedAFR,
        onFilterChange, searchAFR, defectCategoryList, repairCategoryList } = props;
    const [openModal, setOpenModal] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(undefined);
    const [selectedModel, setSelectedModel] = useState(undefined);
    const [selectedDefectCategory, setSelectedDefectCategory] = useState(undefined);
    const [selectedRepairCategory, setSelectedRepairCategory] = useState(undefined);
    const [searchedSerialNo, setSearchedSerialNo] = useState(undefined);
    const [selectedDates, setSelectedDates] = useState(undefined);
    const [icPart, setIcPart] = useState(undefined);
    const [fileList, setFileList] = useState([]);

    const [icPartsList, setIcPartsList] = useState([]);
    const [totalICParts, setTotalICParts] = useState(0);
    const [icPartsLoading, setIcPartsLoading] = useState(false);


    const customerSelectOptions = customerData?.map(customer => ({ label: customer.name, value: customer.id }));
    const modelSelectOptions = productData?.map(product => ({ label: product.name, value: product.id }));
    const defectCategoryOptions = defectCategoryList?.map(defectCategory => ({ label: defectCategory, value: defectCategory }));
    const repairCategoryOptions = repairCategoryList?.map(repairCategory => ({ label: repairCategory, value: repairCategory }));

    const handleClick = () => {
        setOpenModal(true);
    }

    const handleCustomerChange = (value) => {
        setSelectedCustomer(value);
        onFilterChange({
            customer_id: value,
            product_id: selectedModel,
            defect_category: selectedDefectCategory,
            serial_number: searchedSerialNo,
            date: selectedDates,
            repair_category: selectedRepairCategory

        });
    }

    const handleModelChange = (value) => {
        setSelectedModel(value);
        onFilterChange({
            customer_id: selectedCustomer,
            product_id: value,
            defect_category: selectedDefectCategory,
            serial_number: searchedSerialNo,
            date: selectedDates,
            repair_category: selectedRepairCategory

        });
    }

    const handleDefectCategorySearch = (value) => {
        setSelectedDefectCategory(value);
        onFilterChange({
            customer_id: selectedCustomer,
            product_id: selectedModel,
            defect_category: value,
            serial_number: searchedSerialNo,
            date: selectedDates,
            repair_category: selectedRepairCategory

        })
    }
    const handleRepairCategorySearch = (value) => {
        setSelectedRepairCategory(value);
        onFilterChange({
            customer_id: selectedCustomer,
            product_id: selectedModel,
            defect_category: selectedDefectCategory,
            serial_number: searchedSerialNo,
            date: selectedDates,
            repair_category: value
        })
    }

    const handleSerialNoSearch = (value) => {
        setSearchedSerialNo(value);
        onFilterChange({
            customer_id: selectedCustomer,
            product_id: selectedModel,
            defect_category: selectedDefectCategory,
            serial_number: value,
            date: selectedDates,
            repair_category: selectedRepairCategory
        })
    }

    const handleDateChange = (dates, dateStrings) => {
        if (dates && dates.length === 2) {
            const filterDateRange = [dayjs(dateStrings[0]).startOf('day'), dayjs(dateStrings[1]).endOf('day')];
            setSelectedDates(dateStrings);
            onFilterChange({
                customer_id: selectedCustomer,
                product_id: selectedModel,
                defect_category: selectedDefectCategory,
                serial_number: searchedSerialNo,
                date: filterDateRange,
                repair_category: selectedRepairCategory

            });
        } else {
            setSelectedDates(undefined);
            onFilterChange({
                customer_id: selectedCustomer,
                product_id: selectedModel,
                defect_category: selectedDefectCategory,
                serial_number: searchedSerialNo,
                date: undefined,
                repair_category: selectedRepairCategory

            });
        }

    }

    const onChangeFileList = (newFileList) => {
        setFileList(newFileList?.fileList);
    }

    const handleFileUpload = async ({ file, onSuccess, onError }) => {
        const response = await bulkCreateAFRApi(file);
        if (response.isError) {
            onError(response, file);
            showToastError(`Error in uploading file: ${response?.displayableMessage}`);
        } else {
            onSuccess(response, file);
            showToastSuccess("File uploaded Successfully!!");
            setFileList([]);
            onFilterChange({
                customer_id: selectedCustomer,
                product_id: selectedModel,
                defect_category: selectedDefectCategory,
                serial_number: searchedSerialNo,
                date: selectedDates
            });
        }
    }

    const fetchICParts = async (value, type = "", supplier = undefined) => {
        setIcPartsLoading(true);
        const icPartsResponse = await getIncomingPartsAPI(type === SEARCH_MORE_TYPE ? icPartsList.length : 0, value, 10, supplier);
        if (icPartsResponse.isError) {
            showToastError(icPartsResponse.displayableMessage);
        } else {
            setTotalICParts(icPartsResponse.totalCount)
            const dataList = type === SEARCH_MORE_TYPE ? icPartsList : [];
            icPartsResponse.data.forEach(icPart => {
                dataList.push({ value: icPart.id, label: icPart.name });
            });
            setIcPartsList(dataList);
        }
        setIcPartsLoading(false);
    };

    const handleICPartsChange = (icPart) => {
        setIcPart(icPart);
        onFilterChange({
            customer_id: selectedCustomer,
            product_id: selectedModel,
            defect_category: selectedDefectCategory,
            serial_number: searchedSerialNo,
            date: selectedDates,
            repair_category: selectedRepairCategory,
            incoming_part_id: icPart
        });
    };

    const handleICPartSearch = async (searchVal) => {
        setIcPartsList([]);
        await fetchICParts(searchVal);
    }

    const fetchMoreICParts = async () => {
        await fetchICParts("", SEARCH_MORE_TYPE);
    }
    useEffect(() => {
        if (selectedAFR) {
            setOpenModal(true);
        }
    }, [selectedAFR]);

    useEffect(() => {
        fetchICParts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <NewAFRModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                supplierData={supplierData}
                customerData={customerData}
                searchAFR={searchAFR}
                selectedAFR={selectedAFR}
                setSelectedAFR={setSelectedAFR}
                productData={productData}
                defectCategoryList={defectCategoryList}
                repairCategoryList={repairCategoryList}
            />
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 0.8, maxWidth: '80%', overflow: 'hidden' }}>
                    <Space wrap>
                        <div style={{ minWidth: 200, overflowWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                            <Select
                                placeholder='Customer'
                                style={{ minWidth: 200 }}
                                options={customerSelectOptions}
                                onChange={handleCustomerChange}
                                allowClear
                            />
                        </div>
                        <div style={{ minWidth: 200, overflowWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                            <Select
                                placeholder='Model'
                                style={{ minWidth: 200 }}
                                options={modelSelectOptions}
                                onChange={handleModelChange}
                                allowClear
                            />
                        </div>
                        <div style={{ minWidth: 200, overflowWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                            <Select
                                placeholder='Defect Category'
                                style={{ minWidth: 200 }}
                                options={defectCategoryOptions}
                                onChange={handleDefectCategorySearch}
                                allowClear
                            />
                        </div>
                        <div style={{ minWidth: 200, overflowWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                            <Select
                                placeholder='Repair Category'
                                style={{ minWidth: 200 }}
                                options={repairCategoryOptions}
                                onChange={handleRepairCategorySearch}
                                allowClear
                            />
                        </div>
                        <div style={{ minWidth: 200, overflowWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                            <DatePicker.RangePicker style={{ minWidth: 200 }} onChange={handleDateChange} />
                        </div>
                        <div style={{ minWidth: 200, overflowWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                            <Search
                                placeholder="Unit Serial No."
                                style={{ minWidth: 200 }}
                                onSearch={handleSerialNoSearch}
                                allowClear
                            />
                        </div>
                        <div style={{ minWidth: 200, overflowWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                            <SearchSelect
                                style={{ minWidth: 200 }}
                                showSearch
                                allowClear
                                loading={icPartsLoading}
                                value={icPart}
                                placeholder="IC Part (s)"
                                onChange={handleICPartsChange}
                                options={icPartsList}
                                filterOption={false}
                                onSearch={handleICPartSearch}
                                onClear={fetchICParts}
                                loadMore={fetchMoreICParts}
                                hasMoreData={totalICParts > icPartsList.length}
                            />
                        </div>

                    </Space>
                </div>
                <div style={{ flex: 0.2, textAlign: 'right' }}>
                    <Space>
                        <Upload customRequest={handleFileUpload} fileList={fileList} onChange={onChangeFileList}>
                            <Button icon={<UploadOutlined />}>Upload CSV</Button>
                        </Upload>
                        <Button type="primary" icon={<PlusOutlined />} onClick={handleClick}>New</Button>
                    </Space>
                </div>
            </div>
        </>

    )
}