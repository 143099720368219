import styles from "./styles/customerDetailedView.module.css";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import { Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { MoreActionsToggle } from "./moreActionsToggle/moreActionsToggle";
import AddCustomerModal from "../components/addCustomerModal";
import { useState } from "react";

export default function CustomerDetailedViewHeader(props) {

    const { customerDetails, getCustomerList } = props;
    const [customerModal, setCustomerModal] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(customerDetails);

    const navigate = useNavigate();
    const handleCustomerEdit = async () => {
        setSelectedCustomer(customerDetails);
        setCustomerModal(true);
    };
    return (
        <>
            {customerModal && (
                <>
                    <AddCustomerModal
                        openModal={customerModal}
                        setOpenModal={setCustomerModal}
                        selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                        getCustomerList={getCustomerList}
                    />
                </>
            )}
            <div className={styles.detailedViewHeader}>
                <div className={styles.headerFirstHalf}>
                    <div className={styles.icon}>
                        <ArrowLeftLineIcon color="#7D7676" onClick={() => navigate(-1)} />
                    </div>
                    <div className={styles.inspId}>{customerDetails?.name}</div>
                </div>
                <div>
                    <MoreActionsToggle
                        values={{
                            handleCustomerEdit
                        }}
                    />
                </div>
            </div>
        </>
    );
}
