import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import dayjs from "dayjs";

export default function AddCustomerRecordModal(props) {

  const { openModal, setOpenModal, createRecords, selectedRecord, setSelectedRecord, updateRecord, productList } = props;
  const [form] = Form.useForm();


  if (selectedRecord) {
    form.setFieldsValue({ ...selectedRecord, supplyDateTime: dayjs(selectedRecord?.supplyDateTime) });
  } else {
    form.resetFields();
  }

  const handleCancel = () => {
    form.resetFields();
    setSelectedRecord(undefined);
    setOpenModal(false);
  };


  const handleOk = (values) => {
    form.resetFields();
    setOpenModal(false);
    !selectedRecord ? createRecords({ ...values, quantity: parseFloat(values.quantity) })
      : updateRecord(selectedRecord?.id, { ...values, quantity: parseFloat(values.quantity) });
  };

  return (
    <Modal title={!selectedRecord ? "New Supply Record" : "Edit Supply Record"} open={openModal} onOk={handleOk} onCancel={handleCancel}
      footer={[]}
      styles={
        { footer: { textAlign: 'center' } }
      }>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOk}
      >
        <Form.Item label="Order ID" name="orderId">
          <Input style={{ width: '100%' }} size="large" allowClear />
        </Form.Item>
        <Form.Item label="Product" name="productId"
          required
          rules={[
            {
              required: true,
              message: 'Please input valid Product!'
            }
          ]}
        >
          <Select
            options={productList}
          />
        </Form.Item>
        <Form.Item label="Quantity" name="quantity"
          required
          rules={[
            {
              required: true,
              message: 'Please input valid Quantity!'
            }
          ]}
        >
          <Input type='number' style={{ width: '100%' }} size="large" />
        </Form.Item>
        <Form.Item label="Supply Date" name="supplyDateTime"
          required
          rules={[
            {
              required: true,
              message: 'Please input valid Supply Date!'
            }
          ]}
        >
          <DatePicker style={{ width: '100%' }} size="large" />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button key="submit" type="primary" htmlType="submit">
            {!selectedRecord ? `Create` : `Save`}
          </Button>
        </Form.Item>
      </Form>
    </Modal >
  )
}
