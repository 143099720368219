import { Button, Form, Input, Modal, message } from "antd";
import { createCustomerApi, patchCustomerApi } from "../apis/customerApis";

export default function AddCustomerModal(props) {

  const { openModal, setOpenModal, selectedCustomer, setSelectedCustomer, getCustomerList } = props;
  const [form] = Form.useForm();

  if (selectedCustomer) {
    form.setFieldsValue({ ...selectedCustomer });
  }

  const addCustomer = async (customerData) => {
    const response = await createCustomerApi(customerData);
    if (!response.isError) {
      form.resetFields();
      setOpenModal(false);
      getCustomerList({ id: selectedCustomer?.id });
    } else {
      message.error(response.displayableMessage);
    }
  }
  const updateCustomer = async (customerData) => {
    const response = await patchCustomerApi(selectedCustomer.id, customerData);
    if (!response.isError) {
      form.resetFields();
      setSelectedCustomer(undefined);
      setOpenModal(false);
      getCustomerList({ id: selectedCustomer?.id });
    } else {
      message.error(response.displayableMessage);
    }
  }

  const handleOk = (values) => {
    !selectedCustomer ? addCustomer({ ...values }) : updateCustomer({ ...values });
  };

  const handleCancel = () => {
    setSelectedCustomer(undefined);
    setOpenModal(false);
  };

  return (
    <Modal title={selectedCustomer ? "Edit Customer" : "New Customer"} open={openModal} onOk={handleOk} onCancel={handleCancel}
      footer={[]}
      styles={
        { footer: { textAlign: 'center' } }
      }>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOk}
      >
        <Form.Item label="Name" name="name"
          required
          rules={[
            {
              required: true,
              message: 'Please input valid name!'
            }
          ]}
        >
          <Input style={{ width: '100%' }} size="large" />
        </Form.Item>
        <Form.Item label="Code" name="code"
          required
          rules={[
            {
              required: true,
              message: 'Please input valid code!'
            }
          ]}
        >
          <Input style={{ width: '100%' }} size="large" />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button key="submit" type="primary" htmlType="submit">
            {selectedCustomer ? `Edit Customer` : `Create Customer`}
          </Button>
        </Form.Item>
      </Form>
    </Modal >
  )
}
