import { useState, useEffect } from "react";
import PrimaryButton from "../../../common/crunches/primaryButton/primaryButton.js";
import PrimaryCountButton from "../../../common/crunches/primaryCountButton/primaryCountButton.js";
import Container from "react-bootstrap/Container";
import AddLineIcon from "remixicon-react/AddLineIcon";
import Row from "react-bootstrap/Row";
import AddCustomerModal from "../components/addCustomerModal.js";
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../clients/api-constants.js";
import { Button, Table, message } from "antd";
import { searchCustomerApi } from "../apis/customerApis.js";
import { useNavigate } from "react-router-dom";

export default function Customers() {
  const navigate = useNavigate();

  const [customerList, setCustomerList] = useState([]);
  const [customerModal, setCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);

  useEffect(() => {
    getCustomerList();
  }, []);

  const getCustomerList = async (filters = {}, pageOffset = DEFAULT_OFFSET, limit = PAGE_SIZE) => {
    const response = await searchCustomerApi(filters);
    if (!response.isError) {
      setCustomerList(response?.data);
    } else {
      message.error(response.displayableMessage);
    }
  };

  const handleCustomerEdit = async (customer) => {
    setSelectedCustomer(customer);
    setCustomerModal(true);
  };

  return (
    <Container fluid={true}>
      <div
        style={{
          marginTop: "30px",
          marginBottom: "22px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <PrimaryCountButton
          value={{
            child: <>Total Customers</>,
            count: customerList?.length,
          }}
        />
        <PrimaryButton
          value={{
            child: (
              <>
                <AddLineIcon
                  style={{
                    marginRight: "5px",
                    marginTop: "-4px",
                  }}
                />
                New Customer
              </>
            ),
            onClick: () => {
              setCustomerModal(true);
            }
          }}
        />
      </div>
      <Row>
        {customerModal && (
          <>
            <AddCustomerModal
              openModal={customerModal}
              setOpenModal={setCustomerModal}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              getCustomerList={getCustomerList}
            />
          </>
        )}
        <div>
          <Table
            size="small"
            pagination={false}
            dataSource={customerList}
            columns={[
              {
                title: 'Customer Name',
                dataIndex: 'name',
                align: 'center',
                key: 'name',
                render: (_, record) =>
                (<Button type="link" block onClick={() => {
                  // handleCustomerEdit(record); 
                  navigate(`/masterData/customers/${record?.id}`);
                }}>
                  {record.name}
                </Button>)
              },
              {
                title: 'Customer Code',
                dataIndex: 'code',
                align: 'center',
                key: 'code'
              }
            ]}
          />
        </div>
      </Row>
    </Container>
  );
}
