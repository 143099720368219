import Workroom from "../features/workroom/ui/workroom.js";
import Assets from "../features/assets/ui/assets.js";
// import SupplierComponent from "../features/suppliers/ui/supplierComponent";
import People from "../features/people/ui/people.js";
import UserDetails from "../features/userProfile/ui/userDetails.js";
import CalibrationItem from "../features/calibration/components/calibrationItem/calibrationItem.js";
import ApplicationBuilder from "../templates/Application/applicationBuilder.js";
import SupplierComponent from "../features/suppliers/ui/suppliers.js";
import Customers from "../features/customers/ui/customers.js";
import CustomerDetails from "../features/customers/ui/customer-details.js";

const allRoutes = [
  {
    path: "/",
    name: "Workroom",
    component: Workroom,
    withoutNav: true,
    key: "workroom/1",
  },
  {
    path: "/masterData/assets",
    name: "Assets",
    component: Assets,
    key: "assets/1",
  },
  {
    path: "/masterData/people",
    name: "People",
    component: People,
    key: "people/1",
  },
  {
    path: "/masterData/customers",
    name: "Customers",
    component: Customers,
    key: "customers/1",
  },
  {
    path: "/masterData/customers/:customerId",
    name: "Dashboard",
    component: CustomerDetails,
    feature: "dashboard",
    key: "customers/2",
  },
  {
    path: "/masterData/suppliers",
    name: "Suppliers",
    component: SupplierComponent,
    key: "suppliers/1",
  },
  {
    path: "/app-builder/:appId/*",
    name: "Application Builder",
    component: ApplicationBuilder,
    key: "builder/1",
  },
  {
    path: "/application/:appId/*",
    name: "Application",
    component: ApplicationBuilder,
    key: "builder/2",
  },
  {
    path: "/workroom/userprofile",
    name: "User",
    component: UserDetails,
    key: "users/1",
  },
];
export default allRoutes;