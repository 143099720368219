import { Button, DatePicker, Input, message, Select, Space, Table } from "antd";
import { recordCols } from "./utils";
import AddCustomerRecordModal from "../../components/addCustomerRecord";
import { useEffect, useState } from "react";
import styles from "../styles/customerRecordTable.module.css";
import { PlusOutlined } from '@ant-design/icons';
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../../clients/api-constants";
import { createSupplyRecordApi, deleteSupplyRecordApi, searchSupplyRecordApi, updateSupplyRecordApi } from "../../apis/supplyRecordsApi";
import Search from "antd/es/input/Search";
import { searchProductApi } from "../../../assets/apiCalls/productsApis";
import dayjs from "dayjs";


export default function CustomerRecordsTab(props) {
    const { customerDetails } = props;
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(undefined);
    const [productList, setProductList] = useState([]);

    const [selectedOrderId, setSelectedOrderId] = useState(undefined);
    const [selectedProduct, setSelectedProduct] = useState(undefined);
    const [selectedSupplyDate, setSelectedSupplyDate] = useState(undefined);

    const [records, setRecords] = useState([]);

    const getRecordsList = async (filters = { "customer_id": customerDetails?.id }, pageOffset = DEFAULT_OFFSET, limit = PAGE_SIZE) => {
        setLoading(true);
        const response = await searchSupplyRecordApi(filters);
        if (!response.isError) {
            setRecords(response?.data);
        } else {
            message.error(response.displayableMessage);
        }
        setLoading(false);
    };

    const createRecords = async (data) => {
        setLoading(true);
        const response = await createSupplyRecordApi({
            ...data,
            customerId: customerDetails?.id
        });
        if (!response.isError) {
            getRecordsList();
        } else {
            message.error(response.displayableMessage);
        }
        setLoading(false);
    };

    const updateRecord = async (id, data) => {
        setLoading(true);
        const response = await updateSupplyRecordApi(
            id,
            {
                ...data,
                customerId: customerDetails?.id
            });
        if (!response.isError) {
            setSelectedRecord(undefined);
            getRecordsList();
        } else {
            message.error(response.displayableMessage);
        }
        setLoading(false);
    };

    const deleteRecords = async (recordId) => {
        setLoading(true);
        const response = await deleteSupplyRecordApi(recordId);
        if (!response.isError) {
            getRecordsList();
        } else {
            message.error(response.displayableMessage);
        }
        setLoading(false);
    };

    const handleEditRecord = async (record) => {
        setSelectedRecord(record);
        setOpenModal(true);
    };

    const getProductList = async (filters = {}, pageOffset = DEFAULT_OFFSET, limit = PAGE_SIZE) => {
        setLoading(true);
        const response = await searchProductApi(filters);
        if (!response.isError) {
            setProductList(response?.data?.map(product => ({ label: product?.name, value: product?.id })));
        } else {
            message.error(response.displayableMessage);
        }
        setLoading(false);
    };

    const handleOrderIdSearch = (value) => {
        if (value) {
            setSelectedOrderId(value);
            getRecordsList({
                "customer_id": customerDetails?.id,
                "order_id": value,
                "product_id": selectedProduct,
                "supply_date_time": selectedSupplyDate
            });
        } else {
            setSelectedOrderId(undefined);
            getRecordsList({
                "customer_id": customerDetails?.id,
                "product_id": selectedProduct,
                "supply_date_time": selectedSupplyDate
            });
        }
    }
    const handleProductSearch = (value) => {
        if (value) {
            setSelectedProduct(value);
            getRecordsList({
                "customer_id": customerDetails?.id,
                "order_id": selectedOrderId,
                "product_id": value,
                "supply_date_time": selectedSupplyDate
            });
        } else {
            setSelectedProduct(undefined);
            getRecordsList({
                "customer_id": customerDetails?.id,
                "order_id": selectedOrderId,
                "supply_date_time": selectedSupplyDate
            });
        }
    }

    const handleDateSearch = (dates, dateStrings) => {
        if (dates && dateStrings) {
            const filterDateRange = [dayjs(dateStrings[0]).startOf('day'), dayjs(dateStrings[1]).endOf('day')];
            setSelectedSupplyDate(filterDateRange);
            getRecordsList({
                "customer_id": customerDetails?.id,
                "order_id": selectedOrderId,
                "product_id": selectedProduct,
                "supply_date_time": filterDateRange
            });
        } else {
            setSelectedSupplyDate(undefined);
            getRecordsList({
                "customer_id": customerDetails?.id,
                "order_id": selectedOrderId,
                "product_id": selectedProduct
            });
        }
    }

    useEffect(() => {
        if (customerDetails) {
            getRecordsList({ "customer_id": customerDetails?.id });
            getProductList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerDetails])
    return (
        <>
            <AddCustomerRecordModal selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} updateRecord={updateRecord}
                customerDetails={customerDetails} openModal={openModal} setOpenModal={setOpenModal} createRecords={createRecords}
                productList={productList} />
            <div>
                <div className={styles.containerHeader}>
                    <div className={styles.containerHeaderCol1}>
                        <Space>
                            <Search placeholder="Search order ID" onSearch={handleOrderIdSearch} allowClear />
                            <Select placeholder="Search Product" onChange={handleProductSearch} options={productList} style={{ width: 200 }} allowClear />
                            <DatePicker.RangePicker onChange={handleDateSearch} allowClear />
                        </Space>
                    </div>
                    <div className={styles.containerHeaderCol2}>
                        <Button icon={<PlusOutlined />} type="primary"
                            onClick={() => setOpenModal(true)}>Add New Record</Button>
                    </div>
                </div>
                <div className={styles.containerBody}>
                    <Table
                        loading={loading}
                        columns={recordCols(deleteRecords, handleEditRecord)}
                        dataSource={records}
                    />
                </div>
            </div>
        </>
    );
}