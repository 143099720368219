import { Dropdown, Space } from 'antd';
import More2LineIcon from 'remixicon-react/More2LineIcon';
import PencilLineIcon from 'remixicon-react/PencilLineIcon';

export const MoreActionsToggle = (props) => {

    const { handleCustomerEdit } = props.values;

    const items = [
        {
            key: '1',
            label: "Edit Customer",
            icon: <PencilLineIcon size="20px" color={'#7d7676'} />,
            onClick: handleCustomerEdit
        }
    ];

    return (
        <Dropdown
            menu={{
                items
            }}
        >
            <Space>
                <More2LineIcon style={{ cursor: "pointer" }} />
            </Space>
        </Dropdown>

    )
}
