import { ApiClient, ApiClientType } from "../../../clients/api-client";
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../clients/api-constants";

//////////// EQUIPMENT API CALLS  ////////////////////

export const getEquipmentList = async ({
  pageOffset = DEFAULT_OFFSET,
  limit = PAGE_SIZE,
}) => {
  return await ApiClient(
    ApiClient.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/instruments?offset=${pageOffset}&limit=${limit}&name=&includeStation=true&includeCalibrationDetail=true`,
    {}
  );
};

export const getDetailedEquipment = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/instruments/${id}?includeProductCategory=true`,
    {}
  );
};

export const createEquipment = async (values) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/instruments`,
    values
  );
};

export const updateEquipment = async (id, body) => {
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/instruments/${id}`,
    body
  );
};

//////////// MACHINE API CALLS  /////////////////////

export const createMachine = async (values) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/machines`,
    values
  );
};

export const updateMachine = async (id, values) => {
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/machines/${id}`,
    values
  );
};

export const getMachines = async ({
  pageOffset = DEFAULT_OFFSET,
  limit = PAGE_SIZE,
}) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/machines?offset=${pageOffset}&limit=${limit}&includeMachineOperations=true&name=`
  );
};

export const getMachineDetails = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/machines/${id}?includeMachineOperations=true`,
    {}
  );
};

//////////// PRODUCTS API CALLS  /////////////////////

export const getProductCategoryListApi = async (value) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/product-categories?name=${value || ""}`
  );
};

export const getProductListApi = async (value, id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/products?includeProductCategory=false&name=${value || ""
    }&productCategoryId=${id}`
  );
};

export const getProductCategorySpecificDetailsApi = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/product-categories/${id}`
  );
};

export const createProductCategoryApi = async (values) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/product-categories`,
    values
  );
};

export const editProductCategoryApi = async (values, id) => {
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/product-categories/${id}`,
    values
  );
};

export const getProductCategoryDetailsApi = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/products?includeProductCategory=true&name=&productCategoryId=${id}`,
    {}
  );
};

export const getProductDetailsApi = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/products/${id}?includeProductCategory=true`,
    {}
  );
};

export const editProductApi = async (body, id) => {
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/products/${id}`,
    body
  );
};

export const createProductApi = async (body) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/products`,
    body
  );
};

export const searchProductApi = async (body) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/products`
  );
};

//////////// OPERATIONS API CALLS  /////////////////////

export const getOperationsApi = async () => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/operations?name=&includeOperationType=true`
  );
};

export const getOperationTypeApi = async () => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/operation-types?name=${""}`
  );
};

export const createOperationApi = async (body) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/operations`,
    body
  );
};

export const editOperationApi = async (values, id) => {
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/operations/${id}`,
    values
  );
};

export const getOperationDetailsApi = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/operations/${id}?includeOperationType=true`,
    {}
  );
};

//////////// STATIONS API CALLS  /////////////////////

export const getStationListApi = async (pageOffset, limit) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/stations?offset=${pageOffset}&limit=${limit}&name=&code=&includeMachines=true&includeMachineOperations=false`,
    {}
  );
};

export const getDetailedStationApi = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/stations/${id}?includeMachines=true&includeMachineOperations=true`,
    {}
  );
};

export const createStationApi = async (values) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/stations`,
    values
  );
};

export const updateStationApi = async (values, id) => {
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/stations/${id}`,
    values
  );
};
