import styles from "./styles/customerDetailedView.module.css";
import { Tabs } from "antd";
import CustomerDetailsTab from "./tabs/details";
import CustomerRecordsTab from "./tabs/records";

export default function CustomerDetailedViewBody(props) {

    const { customerDetails } = props;

    const tabItems = [
        {
            key: 'details',
            label: 'Details',
            children:
                <CustomerDetailsTab customerDetails={customerDetails} />
        },
        {
            key: 'records',
            label: 'Supply Records',
            children:
                <CustomerRecordsTab customerDetails={customerDetails} />
        }
    ];

    return (
        <div className={styles.detailedBody}>
            <Tabs
                items={tabItems}
                className={styles.customTabNavClass}
            />
        </div>
    )
}
