import { Button, DatePicker, Form, Input, Modal, Select, message } from "antd";
import { createAFRApi, patchAFRApi } from "../../apis/afrAPIs";
import { useEffect, useState } from "react";
import { FileUploader } from "../../../../common/components/fileUploader/fileUploader";
import { ApiClient, ApiClientType } from "../../../../clients/api-client";
import axios from "axios";
import SearchSelect from "../../../suppliers/components/incomingPartsComponent/incomingPartsTable/components/searchSelect";
import { getIncomingPartsAPI, getSuppliersAPI } from "../../../../common/apis/commonApis";
import { showToastError } from "../../../../common/toasters/toasterMessages";
import { searchCustomerApi } from "../../../customers/apis/customerApis";
import { getProductListApi } from "../../../../common/apis/productApis";
import dayjs from "dayjs";

const SEARCH_MORE_TYPE = "searchMore";

export default function NewAFRModal(props) {
    const { openModal, setOpenModal, productData, searchAFR, selectedAFR, setSelectedAFR, defectCategoryList, repairCategoryList } = props;
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [imagesList, setImagesList] = useState([]);

    const [suppliersData, setSuppliersData] = useState([]);
    const [suppliersLoading, setSuppliersLoading] = useState(false);
    const [totalSuppliers, setTotalSuppliers] = useState(0);

    const [customersData, setCustomersData] = useState([]);
    const [customersLoading, setCustomersLoading] = useState(false);
    const [totalcustomers, setTotalCustomers] = useState(0);

    const [productsData, setProductsData] = useState([]);
    const [productsLoading, setProductsLoading] = useState(false);
    const [totalProducts, setTotalProducts] = useState(0);

    const [icPartsList, setIcPartsList] = useState([]);
    const [totalICParts, setTotalICParts] = useState(0);
    const [icPartsLoading, setIcPartsLoading] = useState(false);

    const [selectedSupplier, setSelectedSupplier] = useState(undefined);

    const defectCategoryOptions = defectCategoryList?.map(defectCategory => ({ label: defectCategory, value: defectCategory }));
    const repairCategoryOptions = repairCategoryList?.map(repairCategory => ({ label: repairCategory, value: repairCategory }));

    const handleModelChange = (value) => {
        const selectedProduct = productData?.filter(product => product.id === value)[0];
        form.setFieldValue('productCategory', selectedProduct?.productCategory?.name);
    }


    useEffect(() => {
        if (selectedAFR) {
            form.setFieldsValue({ ...selectedAFR, date: selectedAFR?.date ? dayjs(selectedAFR?.date) : undefined });
            handleModelChange(selectedAFR?.productId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAFR]);

    const addAfr = async (data) => {
        const response = await createAFRApi(data);
        if (response.isError) {
            message.error(response.displayableMessage);
        } else {
            form.resetFields();
            setOpenModal(false);
            searchAFR();
        }
    }
    const updateAfr = async (data) => {
        const response = await patchAFRApi(selectedAFR.id, {
            ...data, media: imagesList?.media, supplierId: data.supplierId || ""
        });
        if (response.isError) {
            message.error(response.displayableMessage);
        } else {
            setSelectedAFR(undefined);
            form.resetFields();
            setOpenModal(false);
            searchAFR();
        }
    }
    const handleOk = async (values) => {
        selectedAFR ? await updateAfr(values) : await addAfr(values);
    };

    const handleCancel = () => {
        setSelectedAFR(undefined);
        form.resetFields();
        setOpenModal(false);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const isInUploadingState = (item) => {
        return item?.status === "uploading";
    };
    const convertToBackendMediaObject = (newFileList) => {
        const formattedFileList = [];
        newFileList?.forEach((ele) => {
            let fileObj = {};
            if (ele.status === "done" && ele.response?.data?.url) {
                fileObj = {
                    name: ele.name,
                    type: ele.type,
                    size: ele.size,
                    url: ele.response?.data?.url
                };
                formattedFileList.push(fileObj);
            } else if (ele.url !== "") {
                formattedFileList.push(ele);
            }
        });
        return {
            media: formattedFileList
        };
    };
    const onChangeFileList = (newFileList) => {
        setFileList(newFileList);

        let isUploadingState = false;
        newFileList?.forEach((item) => {
            const isUploading = isInUploadingState(item);
            if (isUploading) {
                isUploadingState = true;
            }
        });
        if (isUploadingState) {
            return;
        }
        const metaObj = convertToBackendMediaObject(newFileList);
        setImagesList(metaObj);
        // void updateInspectionDetails(inspectionDetails, [metaObj]);
    };

    const fileUpload = async ({ file, onSuccess, onError }) => {
        const urlExtension = selectedAFR ? `/afrs/${selectedAFR.id}/upload` : '/upload';

        const response = await ApiClient(
            ApiClientType.post,
            process.env.REACT_APP_MASTER_BASE_URL,
            urlExtension,
            {}
        );

        if (!response.isError) {
            await axios
                .put(response?.data?.uploadLink?.url, file, {
                    headers: {
                        "Content-Type": file.type
                    }
                })
                .then((res) => {
                    onSuccess(response, file);
                })
                .catch((err) => {
                    onError(err, file);
                });
        } else {
            message.error("Error in uploading media");
        }
    };

    const handleCustomersSearch = async (searchVal) => {
        await fetchCustomers(searchVal);
    };

    const fetchCustomers = async (value, type = "") => {
        setCustomersLoading(true);
        const response = await searchCustomerApi({ name: value }, { limit: 10, offset: type === SEARCH_MORE_TYPE ? customersData.length : 0 });
        if (response.isError) {
            showToastError(response.displayableMessage);
        } else {
            setTotalCustomers(response.totalCount);
            let dataList = type === SEARCH_MORE_TYPE ? customersData : [];
            response?.data?.forEach((customer) => {
                dataList.push({ label: customer.name, value: customer.id });
            });
            setCustomersData(dataList);
        }
        setCustomersLoading(false);
    };

    const fetchMoreCustomers = async () => {
        fetchCustomers("", SEARCH_MORE_TYPE);
    };

    const handleProductsSearch = async (searchVal) => {
        await fetchProducts(searchVal);
    };

    const fetchProducts = async (value, type = "") => {
        setProductsLoading(true);
        const response = await getProductListApi(type === SEARCH_MORE_TYPE ? suppliersData.length : 0,
            10,
            value,
            '',
            true);
        if (response.isError) {
            showToastError(response.displayableMessage);
        } else {
            setTotalProducts(response.totalCount);
            let dataList = type === SEARCH_MORE_TYPE ? productsData : [];
            response.data.forEach((product) => {
                dataList.push({ label: product.name, value: product.id });
            });
            setProductsData(dataList);
        }
        setProductsLoading(false);
    };

    const fetchMoreProducts = async () => {
        fetchProducts("", SEARCH_MORE_TYPE);
    };

    const handleSupplierSearch = async (searchVal) => {
        await fetchSuppliers(searchVal);
    };

    const fetchSuppliers = async (value, type = "") => {
        setSuppliersLoading(true);
        const suppliersResponse = await getSuppliersAPI(
            type === SEARCH_MORE_TYPE ? suppliersData.length : 0,
            value,
            10
        );
        if (suppliersResponse.isError) {
            showToastError(suppliersResponse.displayableMessage);
        } else {
            setTotalSuppliers(suppliersResponse.totalCount);
            const dataList = type === SEARCH_MORE_TYPE ? suppliersData : [];
            suppliersResponse.data.forEach((supplier) => {
                dataList.push({ value: supplier.id, label: supplier.name });
            });
            setSuppliersData(dataList);
        }
        setSuppliersLoading(false);
    };

    const fetchMoreSuppliers = async () => {
        fetchSuppliers("", SEARCH_MORE_TYPE);
    };

    const fetchICParts = async (value, type = "", supplier = selectedAFR?.supplierId) => {
        setIcPartsLoading(true);
        const icPartsResponse = await getIncomingPartsAPI(type === SEARCH_MORE_TYPE ? icPartsList.length : 0, value, 10, supplier);
        if (icPartsResponse.isError) {
            showToastError(icPartsResponse.displayableMessage);
        } else {
            setTotalICParts(icPartsResponse.totalCount)
            const dataList = type === SEARCH_MORE_TYPE ? icPartsList : [];
            icPartsResponse.data.forEach(icPart => {
                dataList.push({ value: icPart.id, label: icPart.name });
            });
            setIcPartsList(dataList);
        }
        setIcPartsLoading(false);
    };

    const handleSupplierChange = async (supplier) => {
        setSelectedSupplier(supplier);
        setIcPartsList([]);
        form.setFieldValue('incomingPartId', undefined);
        await fetchICParts("", "", supplier ? supplier : "");
    };

    const handleICPartSearch = async (searchVal) => {
        setIcPartsList([]);
        await fetchICParts(searchVal, "", selectedSupplier);
    }

    const fetchMoreICParts = async () => {
        await fetchICParts("", SEARCH_MORE_TYPE, selectedSupplier);
    }

    useEffect(() => {
        if (openModal) {
            fetchCustomers();
            fetchProducts();
            fetchSuppliers();
            fetchICParts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal]);

    useEffect(() => {
        if (selectedAFR) {
            setFileList(selectedAFR?.meta?.media || []);
            fetchICParts("", "", selectedAFR?.supplierId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAFR]);
    return (
        <Modal title={selectedAFR ? "Edit AFR" : "New AFR"} open={openModal} onOk={handleOk} onCancel={handleCancel}
            footer={[]}
            styles={
                { footer: { textAlign: 'center' } }
            }>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleOk}
            >
                <Form.Item label="Customer" name="customerId"
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Please select valid customer!'
                        }
                    ]}
                    style={{ width: '100%' }}
                >
                    <SearchSelect
                        showSearch
                        allowClear
                        loading={customersLoading}
                        placeholder="Select Customer"
                        options={customersData}
                        filterOption={false}
                        loadMore={fetchMoreCustomers}
                        onSearch={handleCustomersSearch}
                        onChange={handleModelChange}
                        hasMoreData={totalcustomers > customersData?.length}
                        style={{ width: '100%' }}
                        onClear={() => {
                            fetchCustomers();
                        }}
                    />
                    {/* <Select options={customerSelectOptions} /> */}
                </Form.Item>

                <div style={{ display: 'flex', width: '100%' }}>

                    <Form.Item label="Model" name="productId"
                        required
                        rules={[
                            {
                                required: true,
                                message: 'Please select valid model!'
                            }
                        ]}
                        style={{ width: '100%', marginRight: 10 }}
                    >
                        <SearchSelect
                            showSearch
                            allowClear
                            loading={productsLoading}
                            placeholder="Select Model"
                            options={productsData}
                            filterOption={false}
                            loadMore={fetchMoreProducts}
                            onSearch={handleProductsSearch}
                            onChange={handleModelChange}
                            hasMoreData={totalProducts > productsData?.length}
                            style={{ width: '100%' }}
                            onClear={() => {
                                fetchProducts();
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Product Category" name="productCategory"
                        style={{ width: '100%' }}
                    >
                        <Input disabled />
                    </Form.Item>
                </div>
                <Form.Item label="Date" name="date"
                    initialValue={dayjs()}
                    style={{ width: '100%' }}
                >
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Unit Serial No." name="serialNumber"
                    style={{ width: '100%' }}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Week Code" name="weekCode"
                    style={{ width: '100%' }}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Defect Category" name="defectCategory"
                    style={{ width: '100%' }}
                >
                    <Select
                        placeholder='Defect Category'
                        options={defectCategoryOptions}
                        allowClear
                    />
                </Form.Item>
                <Form.Item label="Defect Description" name="description"
                    style={{ width: '100%' }}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label="Supplier Name" name="supplierId"
                    style={{ width: '100%' }}
                >
                    <SearchSelect
                        showSearch
                        allowClear
                        loading={suppliersLoading}
                        placeholder="Select Suppliers"
                        filterOption={false}
                        options={suppliersData}
                        onChange={handleSupplierChange}
                        onClear={fetchSuppliers}
                        onSearch={handleSupplierSearch}
                        loadMore={fetchMoreSuppliers}
                        hasMoreData={totalSuppliers > suppliersData.length}
                    />
                </Form.Item>
                <Form.Item label="IC Part(S)" name="incomingPartId">
                    <SearchSelect
                        showSearch
                        allowClear
                        loading={icPartsLoading}
                        placeholder="IC Part (s)"
                        options={icPartsList}
                        filterOption={false}
                        onSearch={handleICPartSearch}
                        onClear={fetchICParts}
                        loadMore={fetchMoreICParts}
                        hasMoreData={totalICParts > icPartsList.length}
                    />
                </Form.Item>
                <Form.Item label="Repair Category" name="repairCategory"
                    style={{ width: '100%' }}
                >
                    <Select
                        placeholder='Repair Category'
                        options={repairCategoryOptions}
                        allowClear
                    />
                </Form.Item>
                <Form.Item label="Repair Description" name="repairDescription"
                    style={{ width: '100%' }}
                >
                    <Input />
                </Form.Item>
                {selectedAFR && (<Form.Item label="Upload" valuePropName="media" getValueFromEvent={normFile}>
                    <FileUploader
                        listType={"picture"}
                        fileList={fileList}
                        onChangeFileList={onChangeFileList}
                        customRequest={fileUpload}
                    />
                </Form.Item>)}
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button key="submit" type="primary" htmlType="submit">
                        {selectedAFR ? "Update AFR" : `Create AFR`}
                    </Button>
                </Form.Item>
            </Form>
        </Modal >
    )
}