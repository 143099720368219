import { ApiClient, ApiClientType } from "../../../clients/api-client";

export const searchSupplyRecordApi = async (filters, page = undefined) => {
    const conditionsArr = [];
    const data = page ? { page: page } : {};

    for (const key of Object.keys(filters)) {
        if (filters[key]) {
            if (key === 'supply_date_time' && filters[key]?.length === 2) {
                conditionsArr.push({
                    field: key,
                    operation: ">=",
                    value: filters[key][0]
                });
                conditionsArr.push({
                    field: key,
                    operation: "<=",
                    value: filters[key][1]
                });

            } else {
                conditionsArr.push({
                    field: key,
                    operation: key === 'name' || key === 'order_id' ? 'like' : "in",
                    value: key === 'name' || key === 'order_id' ? filters[key] : Array.isArray(filters[key]) ? filters[key] : [filters[key]]
                });
            }
        }
    }
    return await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/supply-records/search`,
        {
            ...data,
            filter: {
                association: `and`,
                conditions: conditionsArr
            },
            sort: {
                field: "supply_date_time",
                direction: "desc"
            },
            includeProduct: true
        }
    );
}

export const createSupplyRecordApi = async (body) => {
    return await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/supply-records`,
        body
    );
}

export const updateSupplyRecordApi = async (id, body) => {
    return await ApiClient(
        ApiClientType.patch,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/supply-records/${id}`,
        body
    );
}

export const deleteSupplyRecordApi = async (id) => {
    return await ApiClient(
        ApiClientType.delete,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/supply-records/${id}`
    );
}