import { Descriptions } from "antd";
import { onlyDate } from "../../../../utils";

export default function CustomerDetailsTab(props) {
    const { customerDetails } = props;
    const items = [
        {
            key: 'name',
            label: 'Name',
            children: customerDetails?.name,
            span: 3
        },
        {
            key: 'code',
            label: 'Code',
            children: customerDetails?.code,
            span: 3
        },
        {
            key: 'createdAt',
            label: 'Created At',
            children: onlyDate(customerDetails?.created_at),
            span: 3
        }
    ]
    return (
        <>
            <Descriptions bordered items={items} style={{ width: '100%' }} />
        </>
    );
}