import { Button, Popconfirm, Space } from "antd";
import { onlyDate } from "../../../../utils";
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';

export const recordCols = (deleteRecord, handleEditRecord) => [
    {
        title: 'Order ID',
        dataIndex: 'orderId',
        align: 'center',
        key: 'orderID'
    },
    {
        title: 'Product',
        dataIndex: ['product', 'name'],
        align: 'center',
        key: 'product'
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        align: 'center',
        key: 'Quantity'
    },
    {
        title: 'Supply Date',
        dataIndex: 'supplyDateTime',
        align: 'center',
        key: 'SupplyDateTime',
        render: (value) => onlyDate(value)
    },
    {
        title: 'Actions',
        align: 'center',
        key: 'actions',
        render: (_, record) => {
            return (
                <>
                    <Space>
                        <Button type='danger' icon={<EditTwoTone />} onClick={() => handleEditRecord(record)} />
                        <Popconfirm
                            title="Delete supply record"
                            description="Are you sure to delete this record?"
                            onConfirm={() => deleteRecord(record?.id)}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type='danger' icon={<DeleteTwoTone twoToneColor="#eb2f96" />} />
                        </Popconfirm>
                    </Space>
                </>
            )
        }
    }
];