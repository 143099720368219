import { message } from "antd";
import { useParams } from "react-router-dom";
import CustomerDetailedViewHeader from "./customer-detail-header";
import { useEffect, useState } from "react";
import { searchCustomerApi } from "../apis/customerApis";
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../clients/api-constants";
import CustomerDetailedViewBody from "./customer-detail-body";
import styles from "./styles/customerDetailedView.module.css";

export default function CustomerDetails() {
    const params = useParams();
    const { customerId } = params;

    const [selectedCustomer, setSelectedCustomer] = useState(undefined);

    const getCustomerList = async (filters = {}, pageOffset = DEFAULT_OFFSET, limit = PAGE_SIZE) => {
        const response = await searchCustomerApi(filters);
        if (!response.isError) {
            setSelectedCustomer(response?.data?.[0]);
        } else {
            message.error(response.displayableMessage);
        }
    };

    useEffect(() => {
        if (customerId)
            getCustomerList({ 'id': customerId });
    }, [customerId]);

    return (
        <>
            <div className={styles.detailsContainer}>
                <CustomerDetailedViewHeader customerDetails={selectedCustomer} getCustomerList={getCustomerList} />
                <CustomerDetailedViewBody customerDetails={selectedCustomer} />
            </div>
        </>
    );
}